@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');
.bga{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2447+0,1f2029+100 */
background: rgb(46,36,71); /* Old browsers */
background: -moz-linear-gradient(top,  rgb(42, 32, 65) 0%, rgba(31,32,41,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(46,36,71,1) 0%,rgba(31,32,41,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgb(39, 30, 60) 0%,rgba(31,32,41,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e2447', endColorstr='#1f2029',GradientType=0 ); /* IE6-9 */

}
.roboto{
font-family: 'Roboto', sans-serif;
}
.b{
    -webkit-tap-highlight-color: transparent;
}